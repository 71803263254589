import React from "react";
import App from "next/app";
import Head from 'next/head';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import JssProvider from 'react-jss/lib/JssProvider';
import getPageContext from '/src/getPageContext';
import Error from './_error';
import ErrorBoundary from "components/errorBoundry";
// import * as Sentry from '@sentry/browser';
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module'
import { Link } from "routes";

import Headroom from "react-headroom";
import Axios from "/plugins/axios";
// import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";
import { FacebookProvider} from 'react-facebook';
import FontStyleComponent from "../components/fontStyleComponent";
import {AdSlot, DFPManager } from 'react-dfp';
import {c} from '/utils/log';
import { clearInnityAds, loadInnitySpinner } from '../utils/innity';
// import GlobalStyle from '/components/globalStyle';
import dynamic from 'next/dynamic';
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css' // Import the CSS
config.autoAddCss = false // Tell Font Awesome to skip adding the CSS automatically since it's being imported above
// import and add fa icons to the library to be used across the site
import { library } from '@fortawesome/fontawesome-svg-core';
import { findCategory } from '/utils/common';
import Lightbox from "react-image-lightbox";
import { faFacebookF, faViber, faLinkedinIn, faYoutube, faFacebookMessenger, faTwitter, faCreativeCommonsNc, faInstagram } from '@fortawesome/free-brands-svg-icons';
library.add(faFacebookF, faViber, faLinkedinIn, faYoutube, faFacebookMessenger, faTwitter, faInstagram)
// Do not import fab as shown below, we don't need all fab icons imported or added to the library
// import { fab, faFacebookF, faViber, faLinkedinIn, faYoutube, faFacebookMessenger, faTwitter, faCreativeCommonsNc, faInstagram } from '@fortawesome/free-brands-svg-icons';
// library.add(fab, faFacebookF, faViber, faLinkedinIn, faYoutube, faFacebookMessenger, faTwitter, faInstagram)
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const tagManagerArgs = {
  gtmId: 'GTM-5LSZTWB',
}
export default class MyApp extends App {
  constructor(props) {
    super(props);
    this.pageContext = getPageContext();
    this.state = {
      uniReadable: true,
      uniChecked: false,
      appLoaded: false,

      galleryHasContent: true,
      photos: [],
      photoIndex: 0,
      galleryIsOpen: false,
      galleryLoading: true,  

    }
    this.onSlotIsViewableOne = this.onSlotIsViewableOne.bind(this);
    this.onSlotIsViewableTwo = this.onSlotIsViewableTwo.bind(this);
    this.getCatIndex = this.getCatIndex.bind(this);
    this.pin = this.pin.bind(this);
    this.unpin = this.unpin.bind(this);
    this.unfix = this.unfix.bind(this);
    this.renderWidget = this.renderWidget.bind(this);
    this.renderPage = this.renderPage.bind(this);
    this.renderError = this.renderError.bind(this);
    this.pageComponentDidMount = this.pageComponentDidMount.bind(this);
  }
  static async getInitialProps({ Component, ctx }) {
    let pageProps = {};
    if (Component.getInitialProps) {
      c.log('getInitialProps:await Component.getInitialProps(ctx)');
      pageProps = await Component.getInitialProps(ctx);
    }
    const isServer = ctx.isServer;

    // if(typeof ctx.store.categoriesStore == 'undefined') {
    //   let res = await Axios.get('/categories')
    //   ctx.store.categoriesStore = res.data.data;
    // }
    // c.log('categoriesStore')
    // c.log(ctx.store.categoriesStore)
    // let categoriesStore =  ctx.store.categoriesStore
    // return { pageProps, categoriesStore };
    c.log('getInitialProps:ctx.store')
    c.log(ctx.store);

    if(!ctx.store) {
      ctx.store = {};
    }
    if (!ctx.store.categoriesStore) {
      if(ctx.asPath.includes('/widgets/')) {
        ctx.store.categoriesStore = null;
      }
      else {
        c.log('getInitialProps:await Axios.get("/menus/main-menu")')
        let res = await Axios.get('/menus/main-menu')
        ctx.store.categoriesStore = res.data.data;
      }
    }
    let categoriesStore = ctx.store.categoriesStore;

    // let res = await Axios.get('/categories')
    // let categoriesStore = res.data.data;
    // ctx.store.categoriesStore = res.data.data;

    // ctx.store = res.data.data;
    //let categoriesStore = ctx.store.categoriesStore;
    // let referrerName = ctx.req.headers.referer;
    c.log('getInitialProps:categoriesStore')
    // c.log(categoriesStore)
    // let categoriesStore = ctx.store
    return { pageProps, categoriesStore, isServer };
  }
  createUrl = router => {
    const { pathname, asPath, query } = router;
    return {
      get query() {
        return query;
      },
      get pathname() {
        return pathname;
      },
      get asPath() {
        return asPath;
      },
      back: () => {
        router.back();
      },
      push: (url, as) => router.push(url, as),
      pushTo: (href, as) => {
        const pushRoute = as ? href : null;
        const pushUrl = as || href;

        return router.push(pushRoute, pushUrl);
      },
      replace: (url, as) => router.replace(url, as),
      replaceTo: (href, as) => {
        const replaceRoute = as ? href : null;
        const replaceUrl = as || href;

        return router.replace(replaceRoute, replaceUrl);
      }
    };
  };
  loadGTM() {
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});let f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?`&l=${l}`:'';j.async=true;j.src=
    `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-NRW56TX');
    c.log('GTM-NRW56TX Loaded');  // Current GTM-WCJSTPT
  }
  getContentObject (router) {
    return {
      content_name: router.asPath,
      content_category: router.query.id ? router.asPath.replace('/'+router.query.id, '').split('?')[0] : router.asPath,
      content_ids: [router.query.id ? router.query.id : router.asPath],
      content_type: router.pathname,
      value: 1,
      currency: 'USD'
    }
  }

  initDuwunUI () {
    // Get the buttons that open the modal
    let modalOpenBtns = document.getElementsByClassName("modal-open");

    // Get the <span> elements that close the modal
    let modalCloseBtns = document.getElementsByClassName("modal-close");

    for(let i=0; i < modalOpenBtns.length; i++) {
      // When the user clicks the button, open the modal
      modalOpenBtns[i].onclick = function(event) {
        let modalId = event.target.getAttribute("data-modal-id");
        let modal = document.getElementById(modalId);
        modal.style.display = "block";
      }
    }

    for(let i=0; i < modalCloseBtns.length; i++) {
      // When the user clicks on <span> (x), close the modal
      modalCloseBtns[i].onclick = function() {
        let modals = document.getElementsByClassName("modal");
        for(let i=0; i < modals.length; i++) {
          modals[i].style.display = "none";
        }
      }
    }

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function(event) {
      let modals = document.getElementsByClassName("modal");
      for(let i=0; i < modals.length; i++) {
        if (event.target == modals[i]) {
          modals[i].style.display = "none";
        }
      }
    }

    window.DuwunUI = true;
  }

  configureOneSignal () {
    /* these variables are required to work with GTM */
    window.OneSignalEnv = process.env.ENVIRONMENT;
    window.OneSignalConfigured = true;
    switch (process.env.ENVIRONMENT)
    {
      case 'production':
        window.OneSignalAppId = "7cdd39b5-60d2-4202-86ca-4a7076c47aa1";
        break;
      case "staging":
        window.OneSignalAppId = "ea3a6d7f-4543-47da-b911-c178858315cc";
        break;
      case "development":
        window.OneSignalAppId = "4f4e9dc8-d4ce-4f78-bdf4-29df395ef474";
        break;
      default:
        window.OneSignalConfigured = false;
        break;
    }
  }

  configureInnitySpinnerOne () {
    window.innity_country = "MM";
    window.innity_path = "/202007_32920/127027/";
    window.innity_proxy = "proxy_273140";
    window.innity_ord = "ord=[timestamp]"; //Date.now()
  }
  configureInnitySpinnerTwo () {
    window.innity_country = "MM";
    window.innity_path = "/202003_31309/120743/";
    window.innity_proxy = "proxy_265749";
    window.innity_ord = "ord=[timestamp]";
  }

  pageComponentDidMount() {

    this.loadScript("https://www.google.com/recaptcha/api.js?render="+process.env.RECAPTCHA_SITE_KEY);
    // <script src={"https://www.google.com/recaptcha/api.js?render="+process.env.RECAPTCHA_SITE_KEY}></script>

    if(!window.DuwunUI) {
      this.initDuwunUI();
    }

    if (!window.OneSignalConfigured) {
      this.configureOneSignal();
    }

    this.lastScrollTop = 0;
    window.addEventListener('scroll', this.handleScroll);

    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }

    // c.log(this.props.router);
    if(this.props.router){
      // clearInnityAds();
      DFPManager.load();
      // this.configureInnitySpinner();
      // loadInnitySpinner("innity_adslot_265749");
    }

    setTimeout(function(){
      let ele = document.getElementById('uni-readable');
      c.log("checking if uniReadable...");
       // c.log('clientHeight:' + ele.clientHeight + ', clientWidth:' + ele.clientWidth);
      if(ele){
        if(ele.offsetHeight && ele.offsetWidth) {
          c.log('Uni => offsetHeight:' + ele.offsetHeight + ', offsetWidth:' + ele.offsetWidth);
        }
        // c.log('scrollHeight:' + ele.scrollHeight + ', scrollWidth:' + ele.scrollWidth);
        if(ele.offsetWidth && ele.offsetWidth > 20){
          this.setState({
            uniReadable: false
          });
          window.uniReadable = false;
        }
        // this.setState({
        //   uniChecked: true,
        //   appLoaded: true
        // });
        ele.style.display = 'none';
      }
     }.bind(this), 3000);

    if(!window.messageListening){
      window.addEventListener('message', this.receiveMessage )    
      window.messageListening = true;
    }     

  }

  loadScript(src) {
    let tag = document.createElement('script');
    tag.async = false;
    tag.src = src;
    let body = document.getElementsByTagName('body')[0];
    body.appendChild(tag);
  }

  componentDidMount() {
    const DuwunASCIIArt = `\`
      8888888b.  888     888 888       888 888     888 888b    888 
      888  "Y88b 888     888 888   o   888 888     888 8888b   888 
      888    888 888     888 888  d8b  888 888     888 88888b  888 
      888    888 888     888 888 d888b 888 888     888 888Y88b 888 
      888    888 888     888 888d88888b888 888     888 888 Y88b888 
      888    888 888     888 88888P Y88888 888     888 888  Y88888 
      888  .d88P Y88b. .d88P 8888P   Y8888 Y88b. .d88P 888   Y8888 
      8888888P"   "Y88888P"  888P     Y888  "Y88888P"  888    Y888 
                                                                   
      Welcome to Duwun Media. Apparently, you are now in dev pane!   
    \``;
    // const duwunScript = `
    // (function(){let duwun=${duwun};console.info(duwun);}());
    // `;
    console.info(DuwunASCIIArt);

    c.log("_app.js:componentDidMount()");
    document.body.className = document.body.className.replace('preloading', 'loaded')

    // Sentry.init({
    // dsn: "https://819b1847fbaf4fe4a1535b79fcb2a932@sentry.io/1359499"
    // });

    // this.loadGTM();

    if (!window.PX_INITIALIZED) {
      ReactPixel.init('548709379041005', {}, { debug: true, autoConfig: false });
      // ReactPixel.init('355678948310337', {}, { debug: true, autoConfig: false });
      window.PX_INITIALIZED = true
    }
    c.log('_app.js:componentDidMount:ReactPixel.pageView()');
    ReactPixel.pageView();

    // c.log(this.props.router);
    if(this.props.router){

      c.log('_app.js:componentDidMount:ReactPixel.track:ViewContent');
      ReactPixel.track('ViewContent',
        this.getContentObject(this.props.router)
      )
      c.log('_app.js:componentDidMount:this.getContentObject(this.props.router)');
      c.log(this.getContentObject(this.props.router));
      // c.log('componentDidMount:ReactPixel.trackCustom->viewPath');
      // ReactPixel.trackCustom( 'viewPath', {pathname: this.props.router.pathname, asPath: this.props.router.asPath} );
    }

    TagManager.initialize(tagManagerArgs);

    this.props.router.route === '/videoWidget'
    || this.props.router.route === '/galleryWidget'
    || this.props.router.route === '/playlistWidget'
    || this.props.router.route === '/sectionVideoPlaylistWidget'  ? null : this.pageComponentDidMount();
  }

  componentDidUpdate(previousProps, previousState){
    c.log("_app.js:componentDidUpdate()");

    if(this.props.router) {
      if(this.state === previousState){

        // c.log('componentDidUpdate:this.props.router', this.props.router);
        // c.log('componentDidUpdate:this.props.router.asPath', this.props.router.asPath);
        // c.log('componentDidUpdate:previousProps.router.asPath', previousProps.router.asPath);

        c.log('_app.js:componentDidUpdate:ReactPixel.pageView()');
        ReactPixel.pageView();

        c.log('_app.js:componentDidUpdate:ReactPixel.track:ViewContent');
        ReactPixel.track('ViewContent',
          this.getContentObject(this.props.router)
        )
        c.log('_app.js:componentDidUpdate:this.getContentObject(this.props.router)');
        c.log(this.getContentObject(this.props.router));
        // c.log('componentDidUpdate:ReactPixel.trackCustom->viewPath');
        // ReactPixel.trackCustom( 'viewPath', {pathname: this.props.router.pathname, asPath: this.props.router.asPath} );
      }
    }
    if (this.props.router.asPath != '/widgets' && !this.props.router.asPath.startsWith('/widgets/')){
      // not a widget page
    }
    // DFPManager.configureLazyLoad(true);
    // DFPManager.configureDisableInitialLoad(true);    
    // DFPManager.refresh(["div-gpt-ad-1675475999453-0"]);
  }

  componentWillUnmount() {
    c.log("componentWillUnMount()");
    window.removeEventListener('scroll', this.handleScroll);
  }


  handleScroll(event) {
    const scrollUpBtn = document.querySelector('aside.ScrollUpButton__Container');

    const st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
    if (st > this.lastScrollTop){
       // downscroll code
       // c.log("downscroll")
       if(scrollUpBtn)
       scrollUpBtn.style.visibility = 'hidden';
    } else {
       // upscroll code
       // c.log("upscroll")
       if(scrollUpBtn)
       scrollUpBtn.style.visibility = 'visible';
    }
    this.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
  }
  pin = () => {
    if(isMobile && window.videoDetailCardFloated && document.getElementById("videoCard")) {
      document.getElementById("videoCard").className = "stickyVideo"
      document.getElementById("videoCard").style.top = "55px"
      document.getElementById("videoCard").style.position = "fixed"
    }
    if(isMobile && window.articleYoutubeCardFloated && document.getElementById("articleYoutubeCard") && window.pageYOffset > 300) {
      document.getElementById("articleYoutubeCard").style.top = "100px"
      document.getElementById("articleYoutubeCard").style.position = "fixed"
    }
  }
  unpin = () => {
    if(isMobile && window.videoDetailCardFloated && document.getElementById("videoCard")) {
      document.getElementById("videoCard").style.top = "0"
      document.getElementById("videoCard").style.position = "fixed"
    }
    if(isMobile && window.articleYoutubeCardFloated && document.getElementById("articleYoutubeCard") && window.pageYOffset > 300) {
      document.getElementById("articleYoutubeCard").style.top = "0"
      document.getElementById("articleYoutubeCard").style.position = "fixed"
    }
  }
  unfix = () => {
    if(isMobile && window.videoDetailCardFloated && document.getElementById("videoCard")) {
      document.getElementById("videoCard").className = ""
      document.getElementById("videoCard").style.top = "55px"
      document.getElementById("videoCard").style.position = "absolute"
    }
    if(isMobile && window.articleYoutubeCardFloated && document.getElementById("articleYoutubeCard") && window.pageYOffset <= 300) {
      document.getElementById("articleYoutubeCard").style.top = "0"
      document.getElementById("articleYoutubeCard").style.position = "absolute"
    }
  }
  getCatIndex (catStore, router, isVideoPath, isBrandPath, articleCatSlug) {
    // c.log("getCatIndex");
    c.log(router);

    let catSlug = articleCatSlug || router.query.category || (isVideoPath && router.asPath.split("/")[1]) || (isBrandPath && router.asPath.split("/")[3]) || null;
    let catIndexFromQuery = router.pathname === '/' ? -1 : catSlug ? catStore.findIndex((category) => category.slug === `${catSlug}`) : -1;
    // let catIndex = !router.query.brand ? catIndexFromQuery : -1;
    // c.log("catSlug="+catSlug);
    // c.log("catIndex="+catIndexFromQuery);
    // return catIndex;
    return catIndexFromQuery;
  }
  getSubCatIndex (catIndex, catStore, router, isVideoPath, articleSubCatSlug) {
    let subCatSlug = articleSubCatSlug || router.query.subCategory || (isVideoPath && router.asPath.split("/channel/")[1]) || null;
    let subCatIndexFromQuery = catIndex !== -1 ? catStore[catIndex].sub_categories.sort(function(a, b){return a.order - b.order}).findIndex((category) => category.slug === `${subCatSlug}`) : null
    return subCatSlug ? subCatIndexFromQuery : -1   ;
  }

  onSlotIsViewableOne (dfpEventData, isBrandPage) {
    c.log('onSlotIsViewableOne: slot 1x1 viewable!.', dfpEventData);
    if(window && !window.noGlobalAds && !isBrandPage) {
      this.configureInnitySpinnerOne();
      loadInnitySpinner("innity_adslot_273140");
    }
  }
  onSlotIsViewableTwo (dfpEventData, isBrandPage) {
    c.log('onSlotIsViewableTwo: slot 1x1 viewable!.', dfpEventData);
    if(window && !window.noGlobalAds && !isBrandPage) {
      this.configureInnitySpinnerTwo();
      loadInnitySpinner("innity_adslot_265749");
    }
  }
  renderWidget() {
    c.log('_app.js:renderWidget()');
    const { pageProps, Component, router } = this.props;
    const url = this.createUrl(router);
    return (
      <ErrorBoundary>
        <FontStyleComponent />
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        </Head>  
        <Component {...pageProps} url={url} />
      </ErrorBoundary>
    );
  }
  renderError(status) {
    return <Error status={status} />
  }

  receiveMessage = (e) => {
    if (e.data === 'noContent') {
        this.setState({
          galleryHasContent: false,
        });
    }
    if (e.data.clicked !== undefined) {
        this.setState({
            photos: e.data.data,
            galleryLoading: false,
            galleryIsOpen: true, 
            photoIndex: e.data.clickedIndex
        })
      }
    };

  renderPage() {
    const DynamicGlobalStyle = dynamic(() =>  import('../components/globalStyle') )
    const Header = dynamic(() =>  import('../container/DefaultLayout/header') )
    const Footer = dynamic(() =>  import('../container/DefaultLayout/footer') )
    const SubCategoryTabs = dynamic(() =>  import('../components/subCategoryTabs') )
    const { Component, pageProps, router, categoriesStore, isServer } = this.props;
    const isNewRouteArticlePath = pageProps && pageProps.initialProps && pageProps.initialProps.asPath && pageProps.initialProps.asPath.split('/')[1] === 'article';
    const isArticlePath = router.pathname && router.pathname === '/post';
    
    let articleCurrentCat = pageProps && pageProps.initialState && pageProps.initialState.posts && pageProps.initialState.posts.currentPost && pageProps.initialState.posts.currentPost.data && pageProps.initialState.posts.currentPost.data.category ? pageProps.initialState.posts.currentPost.data.category: null;
    let articleCurrentCatSlug = articleCurrentCat ? articleCurrentCat.slug : null;
    let tagCategory = pageProps && pageProps.initialProps && pageProps.initialProps.tag ? pageProps.initialProps.tag : null;
    let currentCatQuery = pageProps && pageProps.initialProps && pageProps.initialProps.catQuery ? pageProps.initialProps.catQuery : null;
    let parentCateObj = findCategory(categoriesStore, currentCatQuery || articleCurrentCatSlug || tagCategory)
    const articleCatSlug = parentCateObj ? parentCateObj.currentCategoryObj && parentCateObj.currentCategoryObj.slug : null;
    const articleSubCatSlug = parentCateObj ? parentCateObj.currentSubCategoryObj && parentCateObj.currentSubCategoryObj.slug : null;
    // let fbReferrerName =  referrerName && referrerName.includes('facebook.com')
    let objectLength = Object.keys(router.query).length;
    let isVideoPath = router.pathname === '/videos' || router.pathname === '/videoDetail' || router.pathname === '/tagVideos' || router.pathname === '/tagTv' || router.pathname === '/categoryVideos';
    let videoCatPath = isVideoPath && router.asPath.split("/")[1];
    let isBrandPath = router.pathname === '/brandPost' || router.pathname === '/brandSubPost' || router.pathname === '/categoryBrand';
    // let catIndexFromQuery = categoriesStore.findIndex((category) => category.slug === `${this.props.router.query.category}`)
    // let catIndex = this.props.router.query.category && !this.props.router.query.brand ? catIndexFromQuery : -1;
    let catIndex = this.getCatIndex(categoriesStore, router, isVideoPath, isBrandPath, articleCatSlug)
    let isHomePage = router.pathname === '/' && catIndex == -1;
    let showSubCategoryTab = !isVideoPath && catIndex !== -1 && categoriesStore[catIndex] && categoriesStore[catIndex].sub_categories.length > 0;
    let showTab = isHomePage || showSubCategoryTab
    // let subCatIndexFromQuery = catIndex !== -1 ? categoriesStore[catIndex].sub_categories.findIndex((category) => category.slug === `${this.props.router.query.subCategory}`) : null
    // let subCatIndex = this.props.router.query.subCategory ? subCatIndexFromQuery : -1   ;
    let subCatIndex = this.getSubCatIndex(catIndex, categoriesStore, router, isVideoPath, articleSubCatSlug)
    const url = this.createUrl(router);
    const uniReadable = this.state.uniReadable;
    // const is404 = router.pathname === '/subCategory' && (!catIndex || !subCatIndex);
    const uniStyle = {
      fontSize: '12px',
      fontFamily: ["SpaceGrotesk", "Padauk", "DuwunFont", "serif"],
      // position: 'absolute',
      // visibility: 'hidden',
      display: 'inline-block',
      height: 'auto',
      width: 'auto',
      whiteSpace: 'nowrap',
      border: '1px solid #fff',
      color: '#fff'
      };
    const isSaga = (pageProps && pageProps.initialState && pageProps.initialState.posts && true) || false;
    if(isSaga)
      c.log("######_app.js::isSaga:" + isSaga + ", pageProps.initialState");
    const hasSagaError = isServer && isSaga && pageProps.initialState.posts.error;
    const catPath = articleCatSlug || router.query.category || '';
    const subCatPath = articleSubCatSlug || !router.query.id && router.query.subCategory || '';

    // const currentCategory = isSaga && pageProps.initialState.posts.currentCategory && pageProps.initialState.posts.currentCategory.category || null;
    const currentSubCategory = subCatPath && categoriesStore && categoriesStore[catIndex] && categoriesStore[catIndex].sub_categories[subCatIndex];
    c.log("_app.js::currentCategory")
    c.log(currentSubCategory ? currentSubCategory : 'null');
    c.log("_app.js::catPath:" + catPath + ", subCatPath:" + subCatPath + ", catIndex" + catIndex);
    const isSubCatMismatch = isSaga && subCatPath && currentSubCategory
    && (currentSubCategory.path != ('/' + catPath + '/' + subCatPath));
    if(isSubCatMismatch)
      c.log("########_app.js::isSubCatMismatch: "+isSubCatMismatch)
    //const tagCategory = categoriesStore && categoriesStore.filter((item) => item.type == 'TAG')
    //const isTagCategory = false;//tagCategory && tagCategory.length !== 0 && tagCategory[0].slug === catPath
    const isBrandPage = router.query.brand != null;
    if(hasSagaError) {
      console.log("hasSagaError: ")
      console.log(pageProps.initialState.posts)
    }
    const hasCatIndex = catIndex != -1;
    const hasSubCatIndex = hasCatIndex && subCatPath && subCatIndex != undefined;
    const currentCategory = hasCatIndex && categoriesStore[catIndex];
    // const currentSubCategory = currentCategory && currentCategory.sub_categories.length > 0 && currentCategory.sub_categories[subCatIndex];
    const careersSlug = 'career-education';
    const isCareersSection = (hasCatIndex && currentCategory && currentCategory.slug == careersSlug)
    || hasSubCatIndex && currentSubCategory && currentSubCategory.slug == careersSlug;
    const isGE2020Section = hasCatIndex && currentCategory && currentCategory.slug == 'election-2020';

    const { photos, photoIndex, galleryIsOpen, galleryLoading } = this.state;
    const images = photos && photos.length > 0 ? photos : null;

    return (
      <React.Fragment>
        {/* <GoogleReCaptchaProvider
          useRecaptchaNet
          reCaptchaKey={process.env.RECAPTCHA_SITE_KEY}
        > */}
        <FontStyleComponent />
        <DynamicGlobalStyle />
        <ErrorBoundary>
          <Head>
            <meta
                name="viewport"
                content="width=device-width, minimum-scale=1, initial-scale=1, shrink-to-fit=no"
              />
          </Head>
          <JssProvider
            registry={this.pageContext.sheetsRegistry}
            generateClassName={this.pageContext.generateClassName}
          >
            <MuiThemeProvider
              theme={uniReadable ? this.pageContext.theme : this.pageContext.themeZawgyi}
              sheetsManager={this.pageContext.sheetsManager}
            >
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />
              <div style={{height: (showTab ? '95px' : '55px'), overflow: 'hidden'}} id="app-bar-wrapper">
              <Headroom style={{zIndex: 999, height: 'initial', minHeight: (showTab ? '95px' : '55px')}}
                  onPin={ this.pin }
                  onUnpin={ this.unpin }
                  onUnfix={ this.unfix } >
                <Header isArticlePath={isArticlePath} articleCurrentCat={articleCurrentCat} catIndex={catIndex} subCatIndex={subCatIndex} asPath={router.asPath} category={categoriesStore[catIndex]} categoryItems={categoriesStore} isVideoPath={isVideoPath} router={router} />
                {showTab &&
                <SubCategoryTabs router={router} showSubCategoryTab={showSubCategoryTab} videoCatPath={videoCatPath} subCatIndex={ subCatPath || router.query.subCategory || isVideoPath ? subCatIndex+1 : 0} category={categoriesStore[catIndex]} />
                }
              </Headroom>
              </div>

              <FacebookProvider appId="991241434272563">
              <div className={isCareersSection ? "careers" : ""} style={{background: isVideoPath ? hasSagaError ? '#fafafa' : '#0A0A0A': '#fafafa'}} id="page-component-wrapper">
              { isCareersSection &&
              <div id="partnership-credit" style={{backgroundColor: '#da6318', color: 'white', textAlign: 'center', fontSize: '12px', lineHeight: '40px'}}>
                In partnership with &nbsp;
                <Link route={"/sites/myjobs"}>
                  <a className="anchor-link">
                    <img src="https://duwun-data.sgp1.cdn.digitaloceanspaces.com/static/careers/Myjobs-Logo-white-transparent.png" alt="logo" title="myjobs" width="80" height="30" style={{objectFit: 'cover',marginBottom: '-12px'}}/>
                  </a>
                </Link>
              </div>
              }
              { isGE2020Section &&
              <div id="partnership-credit" style={{backgroundColor: '#f15a2c', color: 'white', textAlign: 'center', fontSize: '12px', lineHeight: '40px'}}>
                In partnership with &nbsp;
                  <a className="anchor-link" href="https://www.facebook.com/MyanmarEnervonC/">
                    <img src="https://duwun-data.sgp1.cdn.digitaloceanspaces.com/static/ENERVON-C-Logo.png" alt="logo" title="ENERVON-C" width="80" height="30" style={{objectFit: 'cover',marginBottom: '-12px'}}/>
                  </a>
              </div>
              }
              { hasSagaError || isSubCatMismatch ?
                  this.renderError(isSubCatMismatch ? 404 : pageProps.initialState.posts.error.status) :
                  <Component articleCatSlug={articleCatSlug} articleSubCatSlug={articleSubCatSlug} pageContext={this.pageContext} subCatIndex={subCatIndex} categoriesStore={categoriesStore} catIndex={catIndex} {...pageProps} url={url} uniReadable={uniReadable}/>
              }
              </div>
              </FacebookProvider>
              <Footer categoryItems={categoriesStore} uniReadable={uniReadable} routeName={router.route} />
       {/* ************************** For light box ************************* */}
       {!galleryLoading && galleryIsOpen && (
            <Lightbox
                mainSrc={images[photoIndex].cover.teaser}
                imageTitle={images[photoIndex].name}
                imageCaption={images[photoIndex].description}
                nextSrc={images[(photoIndex + 1) % images.length].cover.teaser}
                prevSrc={images[(photoIndex + images.length - 1) % images.length].cover.teaser}
                onCloseRequest={() => this.setState({ galleryIsOpen: false })}
                onMovePrevRequest={() =>
                    this.setState({
                    photoIndex: (photoIndex + images.length - 1) % images.length
                    })
                }
                onMoveNextRequest={() =>
                    this.setState({
                    photoIndex: (photoIndex + 1) % images.length
                    })
                }
            />
        )}              
              {
              // !this.state.uniChecked &&
              <div style={uniStyle} id="uni-readable">က္က</div>
              }

              {
              /* Innity Spinner Ad One */
              <div id="innity_adslot_273140" />
              }
              {
              /* Innity Spinner Ad Two */
              // !this.state.uniChecked &&
              <div id="innity_adslot_265749" />
              // <div id="innity-spinner-ad" />
              }         
              {
              isMobile ?
                <div style={{position : 'fixed', bottom : 0}}>
                <AdSlot
                  sizes={[[1,1]]}
                  dfpNetworkId="73195129"
                  adUnit="innity_spinner_ads_1x1_one"
                  shouldRefresh={()=> true}
                  onSlotIsViewable={dfpEventData => this.onSlotIsViewableTwo(dfpEventData, isBrandPage)}
                />
                </div> : null
              }
    {/* {
      isMobile && url.asPath.split('?').length > 1 && url.asPath.split('?')[1].includes('fixedAds') && 
      <div style={{position : 'fixed', zIndex: '999', left: 0, bottom : 0, width: '100%', height: '50px', textAlign: 'center'}}>
      <AdSlot
        dfpNetworkId="73195129"
        sizes={[[320, 50]]}
        adUnit="Bandpage_mobile-leaderboard"
        shouldRefresh={() => true}
        slotId="div-gpt-ad-1675475999453-0"
      />
      </div>
    }               */}
            </MuiThemeProvider>
          </JssProvider>
        </ErrorBoundary>
        {/* </GoogleReCaptchaProvider> */}
      </React.Fragment>
    );
  }
  render() {
    c.log('_app.js:render()');
    const { router } = this.props;
    return router.asPath == '/widgets' || router.asPath.startsWith('/widgets/') ? this.renderWidget() : this.renderPage()
  }
/*
  _modelExample() {
    return
    <>
    <span className="modal-open" data-modal-id="myModal">Open Modal-1</span>
    <span className="modal-open" data-modal-id="myModal2">Open Modal-2</span>

    <div id="myModal" className="modal">
      <div className="modal-content">
        <div className="modal-header">
          <span className="modal-close">&times;</span>
          <h3>Modal Header</h3>
        </div>
        <div className="modal-body">
          <p>Some text in the Modal Body</p>
          <p>Some other text...</p>
        </div>
        <div className="modal-footer">
          <button className="modal-close">Close</button>
        </div>
      </div>
    </div>

    <div id="myModal2" className="modal">
      <div className="modal-content">
        <div className="modal-header">
          <span className="modal-close">&times;</span>
          <h3>Manage Subscription</h3>
        </div>
        <div className="modal-body">
          <p>Some text in the Modal Body</p>
          <p>Some other text...</p>
        </div>
      </div>
    </div>
    </>
  }
*/
}


