import { createGlobalStyle } from "styled-components";

const FontStyle = createGlobalStyle`
    @font-face {
        font-family:"Padauk";
        src:url("https://duwun-data.sgp1.cdn.digitaloceanspaces.com/fonts/newFonts/Padauk-Bold.ttf") format("truetype");        
        font-display: swap;
        font-weight: 700;
    }
    @font-face {
        font-family:"Padauk";
        src: url("https://duwun-data.sgp1.cdn.digitaloceanspaces.com/fonts/newFonts/Padauk-Regular.ttf") format("truetype");
        font-display: swap;
        font-weight: 400;
    }
    @font-face {
        font-family:"SpaceGrotesk";
        src:url("https://duwun-data.sgp1.cdn.digitaloceanspaces.com/fonts/newFonts/SpaceGrotesk-Bold.ttf") format("truetype");
        font-display: swap;
        font-weight: 700;
    }
    @font-face {
        font-family:"SpaceGrotesk";
        src: url("https://duwun-data.sgp1.cdn.digitaloceanspaces.com/fonts/newFonts/SpaceGrotesk-SemiBold.ttf") format("truetype");
        font-display: swap;
        font-weight: 600;
    }
    @font-face {
        font-family:"SpaceGrotesk";
        src: url("https://duwun-data.sgp1.cdn.digitaloceanspaces.com/fonts/newFonts/SpaceGrotesk-Medium.ttf") format("truetype");
        font-display: swap;
        font-weight: 500;
    }
    @font-face {
        font-family:"SpaceGrotesk";
        src: url("https://duwun-data.sgp1.cdn.digitaloceanspaces.com/fonts/newFonts/SpaceGrotesk-Regular.ttf") format("truetype");
        font-display: swap;
        font-weight: 400;
    }
    @font-face {
        font-family:"SpaceGrotesk";
        src: url("https://duwun-data.sgp1.cdn.digitaloceanspaces.com/fonts/newFonts/SpaceGrotesk-Light.ttf") format("truetype");
        font-display: swap;
        font-weight: 300;
    }
    @font-face {
        font-family:"DuwunFont";
        src:url("https://duwun-data.sgp1.cdn.digitaloceanspaces.com/fonts/v1.6/Duwun_lite_v1.6.woff2") format("woff2"),
            url("https://duwun-data.sgp1.cdn.digitaloceanspaces.com/fonts/v1.6/Duwun_lite_v1.6.woff") format("woff"),
            url("https://duwun-data.sgp1.cdn.digitaloceanspaces.com/fonts/v1.6/Duwun_lite_v1.6.ttf") format('truetype');
        font-display: swap;
    }
    /* 
    @font-face {
        font-family:"PhikselSmooth";
        src:url("https://duwun-data.sgp1.cdn.digitaloceanspaces.com/react-assets/fonts/MyanmarPhikselSmooth.ttf") format("truetype");
        font-display: swap;
    } 
    */
    /*
    @font-face {
        font-family:"PyiDaungSuNew";
        src:url("https://duwun-data.sgp1.cdn.digitaloceanspaces.com/react-assets/fonts/Pyidaungsu-2.5_Regular.ttf") format("truetype");
        font-display: swap;
    }
    */
    /* 
    @font-face {
        font-family: 'Roboto', sans-serif;
        font-display: swap;
        } 
    */
    body {
        padding: 0px;
        font-family: "SpaceGrotesk", "Padauk", "DuwunFont", "serif";
        font-display: swap;
        position: relative;
    }     
  `

function FontStyleComponent (){
    return(
        <FontStyle />
    )
}
export default FontStyleComponent;