import React, { Component } from "react";
import Head from "next/head";
import NProgress from "components/NProgress";
// import Button from '@material-ui/core/Button';
import { Link } from "routes";
import styled from "styled-components";
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    dataLayer: {
        event: 'errorBoundaryEvent',
        errorPage: '#',
        errorStatus: '',
        errorMessage: ''
    }
}
class Error extends Component {
  render() {
    const { classes } = this.props;
    const ErrStatus = styled.div`
        position: absolute;
        top: 25%;
        left: 2%;
        right: 0;
        bottom: 0;
        font-size: 3rem;
        color: #f4eeee;
        @media (max-width: 768px) {
            top: 24%;
            font-size: 2.2rem;
        }
        `;
        const CustomGrid = styled.span`
        text-align: center;
        flex-grow: 0;
        max-width: 100%;
        flex-basis: 100%;
        @media (min-width: 960px) {
            max-width: 33.333333%;
            flex-basis: 33.333333%;
        }
        @media (max-width: 960px) {
            max-width: 50%;
            flex-basis: 50%;
        }
        @media (max-width: 768px) {
            top: 24%;
            font-size: 2.2rem;
            max-width: 100%;
            flex-basis: 100%;
        }
        `

    return (
      <div>
        <Head>
          <title>Error Page</title>
        </Head>
        <NProgress />
        <div id="error-loading">
            loading...
        </div>
        <div id="error-preloading" style={{display: 'none'}}>
            <div style={{ 
                    margin: '5% 0px', 
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    boxSizing: 'border-box'
                }}
            >
                <CustomGrid>
                   <div style={{position: 'relative'}}>
                      <ErrStatus>{this.props.status || 404 } </ErrStatus>
                      <img src='https://duwun-data.sgp1.cdn.digitaloceanspaces.com/react-assets/image/Page-not-found-image.png' style={{ width: '100%', height: '100%'}} alt="Page Not Found" /> 
                    </div>
                    <h1 style={{ fontWeight: 'bold', fontSize: '24px' }} >
                        Page Not Found
                    </h1>
                    <p style={{ color: 'rgb(156, 151, 151)', fontSize: '1rem' }}>
                        The page you are trying to get to does not exits! It is probably our fault but just in case, please check that the URL you entered is correct.
                    </p>
                    <Link route="/">
                        <a className="anchor-link">
                            {/* <Button variant="contained" color="primary" style={{marginTop: 20}}> */}
                            <button>
                                Go To Home
                            </button>
                               
                            {/* </Button> */}
                        </a>
                    </Link>
                </CustomGrid>
            </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
   document.getElementById('error-preloading').style.display = 'block';
   document.getElementById('error-loading').style.display = 'none';
   tagManagerArgs.dataLayer.errorPage = window.location.href;
   tagManagerArgs.dataLayer.errorStatus = this.props.status || '404';
   tagManagerArgs.dataLayer.errorMessage = '404: page not found';
   TagManager.dataLayer(tagManagerArgs);
  }
}


export default Error;





