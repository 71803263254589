export const clearInnityAds = () => {
  var innityWrapper = document.querySelectorAll('.innity-apps-reset');
  if(innityWrapper !== null) {
    innityWrapper.forEach(ele => ele.parentNode.removeChild(ele));
    try {
      top.document.iCollide = 0;
    } catch (e) {
      document.iCollide = 0;
    }
  }
}

export const loadInnitySpinner = (id) => {
  var innityWrapper = document.getElementById(id);
  if(innityWrapper !== null) {

    var head = document.getElementsByTagName('head')[0];
    var script = document.createElement('script');
    var scriptSpinner = document.createElement('script');

    script.type = 'text/javascript';
    scriptSpinner.type = 'text/javascript';

    script.onload = function() {
      // console.log('innity spinner loaded');
    }

    script.src = '/static/ads/innity/innity.js';
    head.appendChild(script);
    // head.removeChild(script);

    if(window.innity_proxy)
      scriptSpinner.src = '/static/ads/innity/spinner/'+window.innity_proxy+'.js'; //proxy_265749
    else
      scriptSpinner.src = '/static/ads/innitySpinner.js';

    head.appendChild(scriptSpinner);
    // head.removeChild(scriptSpinner);
  }
}

export const loadInnityRoller = () => {
  var innityWrapper = document.getElementById("innity-ads-roller");
  if(innityWrapper !== null) {

    var head = document.getElementsByTagName('head')[0];
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.onload = function() {
      // console.log('innity roller loaded');
    }
    script.src = '/static/ads/innityRoller.js';
    head.appendChild(script);
    head.removeChild(script);
  }
}

export const clearInnityAdsInlineVideo = () => {
    try {
      top.document.inreadCollide = 0;
    } catch (e) {
      document.inreadCollide = 0;
    }
}

export const innityInlineVideo = () => {
  var innityWrapper = document.getElementById("innity-ads-inline-video");
  if(innityWrapper !== null) {

    var head = document.getElementsByTagName('head')[0];
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.onload = function() {
      // console.log('innity inline video loaded');
    }
    script.src = '/static/ads/innityInlinevideo.js';
    head.appendChild(script);
    head.removeChild(script);

  }
}

export const innityInline3d = () => {
  var innityWrapper = document.getElementById("innity-ads-inline-3d");
  if(innityWrapper !== null) {

    var head = document.getElementsByTagName('head')[0];
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.onload = function() {
      // console.log('innity inline 3d loaded');
    }
    script.src = '/static/ads/innity/innityThreeDPost.js';
    head.appendChild(script);
    head.removeChild(script);

  }
}



