
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Link, Router } from '/routes'
import classNames from 'classnames';
import { getCategoryColors } from '/utils/common';

const styles = theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    borderRadius: '0',
    // '&::before':{
    //   content: '""',
    //   width: '100%',
    //   height: 48,
    //   backgroundColor: '#000',
    //   opacity: 0.2,
    //   position: 'absolute'
    // }
  },
  removeOpacityRoot: {
    flexGrow: 1,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    borderRadius: '0',
  },
  tabsIndicator: {
    backgroundColor: '#fff',
  },
  tabRoot: {
    color: '#fff',
    minWidth: 72,
    minHeight: 40,
    opacity: 1,
    '&:focus': {
      color: '#fff',
    },
  },
  tabSelected: {
    fontWeight: 'bold'
  },
  customLabelContainer: {
    padding: 0,
    fontSize: 18,
    textTransform: 'capitalize'
  }
 
});

class SubCategoryTabs extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      value : props.subCatIndex
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (event, value) => {
    this.setState( { value } );
  };

  render() {
    const { classes, category, categoryUrl, subCatIndex, videoCatPath, showSubCategoryTab } = this.props;
    const videoSubCatPath = videoCatPath && ((videoCatPath == "tv" && "categoryTv") || "categoryVideos");
    // console.log("videoCatPath:"+videoCatPath);
    // console.log("videoSubCatPath:"+videoSubCatPath);
if(showSubCategoryTab){
    const value = subCatIndex;
    const allSubCategories = category && category.sub_categories;
    const ascendSortSubCategories = allSubCategories && allSubCategories.length > 0 && allSubCategories.sort(function(a, b){return a.order - b.order});  
    const items = JSON.parse(JSON.stringify(ascendSortSubCategories));
    // const subCategoryItem = subCatIndex != -1 && ascendSortSubCategories[subCatIndex];    
    // const color = subCatIndex != -1 ? subCategoryItem && subCategoryItem.color : category && category.color; 
    const color = category && category.color; 
    // let secondaryColor = category.other_colors && category.other_colors.secondary_color !== null ? category.other_colors.secondary_color : '#0A0A0A' ;
    let secondaryColor = category.other_colors && category.other_colors.secondary_color !== null ? category.other_colors.secondary_color : getCategoryColors(category.slug).secondary;
    items.splice(0, 0, {"name": category && category.name,
    "slug": category && category.slug,
    "color": color,
    "other_colors": {secondary_color: secondaryColor},
  "path": category && category.path});
    return (
      <Paper className={classNames(classes.root, 'shadowWrapper')} style={{background: secondaryColor, height: 40}}>
        <Tabs
          value={value}
          onChange={this.handleChange}
          classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
          variant="scrollable" 
          scrollButtons="off"
        >
          {items && items.length > 0 && items.map((item, index) =>{
           return (
            <Tab
              classes={{ root: classes.tabRoot, selected: classes.tabSelected, labelContainer: classes.customLabelContainer }}

              key={index}
              label={<Link key={index}  
                route={ index == 0 ? (videoCatPath || item.path) : item.type === 'TAG' ? "tagItems" : (videoSubCatPath || item.path ) } 
                params={index == 0 ? (videoCatPath ? {} :item.path) : item.type === 'TAG' ? { tag: item.slug } : (videoCatPath ? item.path : {})}>
              <a className="anchor-link sub-category-link sub_categories_gtm">{index == 0 ? "All" : item.name}</a></Link>}
            /> 
           
            )}
            )}
        </Tabs>
      
      </Paper>
    );
  }
  else{
    return(
    <Paper className={classNames(classes.removeOpacityRoot, 'shadowWrapper')} style={{background: '#F9AE3C', opacity: 1, height: 40}}>
   
  </Paper>
    )
  }
}
}

SubCategoryTabs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SubCategoryTabs);