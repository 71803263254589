import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import { Link } from "routes";

const styles = theme => ({ 
    brandTeaserLogo: {
        width: '22px!important', 
        height: 22
      },
})

class BrandTeaserLogo extends Component {
  render() {
    const { classes, brandTeaserLogo, brandSlug, width, height, imgStyle } = this.props;
    return (
        <Link route="brandProfile" params={{ brandProfile: brandSlug }}>
            <a className="anchor-link" style={{ marginBottom: -10 }}>
                {(width && height) ?
                <img src={brandTeaserLogo} alt="logo" title={brandSlug} width={width} height={height} style={imgStyle?imgStyle:{}}/> 
                :
                <img src={brandTeaserLogo} alt="logo" title={brandSlug} className={classes.brandTeaserLogo}/> 
                }
            </a>
     </Link>
    );
  }
}

export default withStyles(styles)(BrandTeaserLogo);
