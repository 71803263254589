import { createGlobalStyle } from "styled-components";

const GlobalStyleComponent = createGlobalStyle` 
.grecaptcha-badge{
  visibility: hidden;
}
.eventWidgetGalleryIframe{
  width: 100%; 
  min-height: 300px;
  border: 0;
  margin: 0 auto; 
  display: block;
}
.widgetGalleryIframe{
  min-height: 600px; 
  width: 50%; 
  border: 0;
  margin: 0 auto; 
  display: block;
}
@media (max-width: 768px) {
  .widgetGalleryIframe{
      width: 100%;
      min-height: 80vh; 
      margin-bottom: 10px;
  }
}
@media (max-width: 400px) {
  .widgetGalleryIframe{
      width: 100%;
      min-height: 90vh; 
      margin-bottom: 10px;
  }
}
@media (max-width: 320px) {
  .widgetGalleryIframe{
      width: 100%;
      min-height: 105vh; 
      margin-bottom: 10px;
  }
}
.agendaSlide .slick-dots{
  bottom: -40px;
}
.agendaSlide .slick-dots li{
  margin: 0
}
.eventSlider .slick-next:before,
.eventSlider .slick-prev:before{
  color: #0A0A0A;
}
.playlistOverlay{
    margin: 0;
    padding: 23% 0px;
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    color: #fff;
    background: rgba(0,0,0,0.75);
    text-align: center;
    opacity: 0;
    transition: opacity 0.25s ease;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
}
.playlistHoverWrapper:hover .playlistOverlay {
  opacity: 1;
}
// ******************** slider *********************
.custom-slider .slick-slider {
  overflow-x: hidden;
  background: #ccc;

}
.custom-slider .slick-dots{
  position: absolute;
  bottom: 0;
}
.custom-slider .slick-dots button{
  width: 10px;
  height: 10px;
  padding: 0px;
  display: initial;
}
.custom-slider .slick-dots li {
  width: 3px;
  padding: 10px 4px;
}
.custom-slider .slick-dots li button:before{
  background: url(https://duwun-data.sgp1.cdn.digitaloceanspaces.com/static/image/starItalicOutline.svg) no-repeat center center;
  background-size: 10px;
  content: '';
  opacity: 1;
}
.custom-slider .slick-dots .slick-active > button::before{
  content: '';
  background: url(https://duwun-data.sgp1.cdn.digitaloceanspaces.com/static/image/starItalicFill.svg) no-repeat center center;
  background-size: 10px;
}

.sale-slider .slick-slider {
  overflow-x: hidden;
  background: url(https://duwun-data.sgp1.cdn.digitaloceanspaces.com/static/sales/sales-bg-image.jpeg) bottom center;
  background-size: cover;

}
.sale-slider .slick-dots li {
  margin: 0px 8px;
}
.sale-slider .slick-dots{
  bottom: 30px;
}
.sale-slider .slick-dots li button::before{
  background-size: 12px;
}
.sale-slider .slick-slider{
  margin-bottom: -5px;
}
.sale-slider .slick-dots li button:before{
  background: url(https://duwun-data.sgp1.cdn.digitaloceanspaces.com/static/sales/star-outline.svg) no-repeat center center;
}
.sale-slider .slick-dots .slick-active > button::before{
  background: url(https://duwun-data.sgp1.cdn.digitaloceanspaces.com/static/sales/star-fill.svg) no-repeat center center;

}

.variable-width{
  overflow-x: hidden;
}
.variable-width-slider{
  position: relative;
  height: 406px;
}

.variable-width-slider .first-slider{
  transform: rotate(180deg);
  position: relative;
  z-index: 2;
  margin: 40px 0px 0px 0px;
}
.variable-width-slider .custom-slide-item{
  padding-left: 40px;
}
.variable-width-slider .first-slider .slick-prev{
  display: none!important;
}
.variable-width-slider .custom-slide-item:focus-visible{
  outline: none;
}

// ******************** end slider ************************
// ************** start playlist slider ***********
.playlist-slider .slick-slide img {
  height: 79px;
  object-fit: cover;
  width: 100%;
}
.playlist-slider .nowPlaying{
  color: #fefefe;
  padding: 6px;
  position: absolute;
  line-height: 1;
  background-color: #e30000;
  opacity: .75;
  bottom: 0px;
  font-size: 14px;
}
.playlist-slider .nowPlayingHide{
  display: none;
}
.playlist-slider .slick-slide{
  padding: 8px;
}

/* ************** end playlist slider *********** */
.layout{
  max-width: 1140px;
  width: 1140px;
}
@media (min-width: 978px) and (max-width: 1236px) {
  .layout{
    width: 100%;
  }
}
.mobileLayout{
  padding: 16px 16px 0px;
  width: 100%;
}

/* ****************************************** */
/* @import url('https://fonts.googleapis.com/css?family=Roboto'); */


 
.navigationActive{
  background: #F9461E!important;
  color: #fff!important;
}
.navigationActive{
  background: #F9461E!important;
  color: #fff!important;
  text-transform: uppercase;
}
    
.headerNavigationActive{
  color: #fff!important;
  text-transform: uppercase;
}
.headerNavigationColor{
  color: #fff!important;
  opacity: 0.7;
  text-transform: uppercase;
}
.subCategoryTabActive{
  color: #fff!important;
  text-transform: uppercase;
  border-bottom: 2px solid #fff;
}
/* ********** load more *********** */

@keyframes ld-spin {
0% {
  -webkit-transform: rotate(0);
  transform: rotate(0);
  animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
50% {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
100% {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
}
@-webkit-keyframes ld-spin {
0% {
  -webkit-transform: rotate(0);
  transform: rotate(0);
  animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
50% {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
100% {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
}
.ld.ld-spin {
-webkit-animation: ld-spin 1s infinite;
animation: ld-spin 1s infinite;
}
.ld-ring{
width: 1em;
height: 1em;
position: relative;
color: inherit;
display: inline-block;
}

.ld-ring::after{
position: absolute;
margin: auto;
width: 100%;
height: 100%;
top: 0;
left: 0;
right: 0;
bottom: 0;
content: " ";
display: inline-block;
background: center center no-repeat;
background-size: cover;
border-radius: 50%;
border-style: solid;
border-width: 0.15em;
-webkit-background-clip: padding-box;
border-color: #fff #fff #fff transparent;
box-sizing: border-box;
}

/************* pagination ***************/
.pagination{height:36px;margin:0;padding: 0;font-size:0.98em}
.pager,.pagination ul{margin-left:0;*zoom:1}
.pagination ul{padding:0;display:inline-block;*display:inline;margin-bottom:0;-webkit-border-radius:3px;-moz-border-radius:3px;border-radius:3px;-webkit-box-shadow:0 1px 2px rgba(0,0,0,.05);-moz-box-shadow:0 1px 2px rgba(0,0,0,.05);box-shadow:0 1px 2px rgba(0,0,0,.05)}
.pagination li{display:inline}
.pagination a{float:left;padding:0 12px;line-height:30px;text-decoration:none;border:1px solid #ddd;border-left-width:0}
.pagination .active a,.pagination a:hover{background-color:#f5f5f5;color:#F9461E}
.pagination .active a{color:#fff;cursor:default;background-color:#F9461E}
.pagination .disabled a,.pagination .disabled a:hover,.pagination .disabled span{color:#94999E;background-color:transparent;cursor:not-allowed}
.pagination li:first-child a,.pagination li:first-child span{border-left-width:1px;-webkit-border-radius:3px 0 0 3px;-moz-border-radius:3px 0 0 3px;border-radius:3px 0 0 3px}
.pagination li:last-child a{-webkit-border-radius:0 3px 3px 0;-moz-border-radius:0 3px 3px 0;border-radius:0 3px 3px 0}
.pagination-centered{text-align:center}
.pagination-right{text-align:right}
.pager{margin-bottom:18px;text-align:center}
.pager:after,.pager:before{display:table;content:""}
.pager li{display:inline}
.pager a{display:inline-block;padding:5px 12px;background-color:#fff;border:1px solid #ddd;-webkit-border-radius:15px;-moz-border-radius:15px;border-radius:15px}
.pager a:hover{text-decoration:none;background-color:#f5f5f5;cursor:not-allowed}
.pager .next a{float:right}
.pager .previous a{float:left}
.pager .disabled a,.pager .disabled a:hover{color:#999;background-color:#fff;cursor:not-allowed}
.pagination .prev.disabled span{float:left;padding:0 12px;line-height:30px;text-decoration:none;border:1px solid #ddd;border-left-width:1}
.pagination .next.disabled span{float:left;padding:0 12px;line-height:30px;text-decoration:none;border:1px solid #ddd;border-left-width:0}
.pagination li.active, .pagination li.disabled {
  float:left;line-height:30px;text-decoration:none;border-left-width:0
}

.pagination li:first-child {
  border-left-width: 1px;
}
.pagination li{
  cursor: pointer;
}
/************* ads style ***************/
.ads-global-style{
  text-align : center;
  // padding: 20px 0px 20px;
  background-color: #f7f7f7;
}
.ads-style{
  text-align : center;
  padding: 20px 0px 20px;
}
.adBox {
  position: relative;
}
/****
.ads-global-style:after,
.ads-style:after {
*****/
.adBox:after {
  content: 'Advertisement';
  font-size: 0.7em;
  color: #9a9a9a;
  font-weight: 100;
  font-style: normal;
  margin-top: 0;
  position: absolute;
  width: 100%;
  left: 0;      
}    
@media only screen and (max-width: 600px) {
  .ads-style{
    padding: 10px 0px 26px;
  }
}

.anchor-link{
  text-decoration: none;
}
.footer-anchor-link{
  text-decoration: none;
  color: #fff;
  display: inline-block;
}
.sub-category-link{
  color: #fff;
  padding: 6px 20px;
}
   
/****************** for detail page ********************/
iframe.ql-video, iframe.es-yt-iframe, iframe.es-fb-iframe, iframe.fb-widget, iframe.tweet-widget, iframe.instagram-widget {
  width: 80%;
  padding-left: 20%;
}
iframe.ql-video, iframe.es-yt-iframe, iframe.es-fb-iframe {
  min-height: 400px;
}      
iframe.fb-widget {
  min-height: 650px;
}     
@media screen and (max-width: 600px) {
  iframe.tweet-widget, iframe.instagram-widget{
      width: 100%!important;
      padding:0!important;
  }
  .fb-comments.fb_iframe_widget span {
    width: 100%!important;
  }
  .fb-comments.fb_iframe_widget {
    width: 100%!important;
  }
  .fb-comments.fb_iframe_widget iframe{
    width: 100%!important;
    position: static!important;
  }
  iframe.ql-video, iframe.es-yt-iframe, iframe.es-fb-iframe, iframe.fb-widget {
    width: 100%;
    padding-left: 0;
  }
  iframe.ql-video, iframe.es-yt-iframe, iframe.es-fb-iframe {
    min-height: 200px;
  }      
  iframe.fb-widget {
    min-height: 480px;
    position: relative;
    top: -70px;
    margin-top: 30%;
    margin-bottom: -30%;
  }
  .sub-category-link{
    color: #fff;
    padding: 6px 12px;
  }
}

//  ****************  for box-shadow of nav material ui ***************
.headroom--unfixed .shadowWrapper{
  box-shadow: initial;
  transition: all 0.1s ease-in;
}
// ****************** end detail page ********************
.stickyVideo {
  // position: -webkit-sticky;
  // position: fixed;
  // top: 0px;
  right: 0px;
  width: 100%;
  transition: all 0.2s ease-in-out 0s;
  z-index: 3
}
.floatVideo{
  position: fixed!important;
  bottom: 10px;
  left: 20px;
  margin: 0!important;
  width: 300px!important;
  height: 170px!important;
  transition: all 0s ease-in-out 0s;
  z-index: 1;
}
      
@media (max-width: 978px) {
  .floatVideo{
    // width: 248px!important;
    // height: 145px!important;
    width: 100%!important;
    height: 130px!important;
    top: 0;
    left: 0;
  }
}
#articleYoutubeCard {
  position: relative;
}

.spaceHeight {
  height: calc(100vw * 0.5625)
}

/*
// #videoCard > iframe{
//   height: calc(100vw * 0.5625)!important
// }
*/
.testingVideo{
  width: 500px;
  height: 700px;
}
.for-float-position .jw-flag-floating .jw-wrapper{
  left: 14px;
  right: initial;
}

#countdown-wrapper {
  position: absolute;
  // top: 40%;
  top: 23%;
  right: 0;
  left: 0;
  z-index: 1;
  background-color: transparent;
  margin: auto;
}

#countdown {
  position: relative;
  margin: auto;
  height: 80px;
  width: 80px;
  text-align: center;
  z-index: 1;
  top: calc((50%) - (40px));
  margin-top: 0;
  cursor: pointer;
  background-color: #0A0A0A;
  border-radius: 50%;
}
#countdown-cancel:hover,
div#countdown:hover {
  /* background-color: rgba(255,255,255,0.3); */
  background-color: #000;
}
#countdown-number {
  color: #333;
  font-weight: bold;
  display: inline-block;
  line-height: 80px;
  border:1px solid #555;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  box-shadow: 0 0 12px #aaaaaa;
}
#countdown .arrow-right {
  position: absolute;
  z-index:-1;
  top: 20px;
  left: 30px;
  width: 0; 
  height: 0; 
  display: inline-block;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;  
  border-left: 30px solid white;  
}
#countdown svg {
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  transform: rotateY(-180deg) rotateZ(-90deg);
}
#countdown svg circle {
  stroke-dasharray: 225px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 4px;
  stroke: white;
  fill: none;
  animation: countdown 5s linear infinite forwards;
}
@keyframes countdown {
  from {
  stroke-dashoffset: 0px;
  }
  to {
  stroke-dashoffset: 225px;
  }
}
#countdown-cancel {
  color: #fff;
  cursor: pointer;
  position: absolute;
  text-align: center;
  // top: calc((50%) + 60px);
  top: calc((50%) + 80px);
  left: calc((50%) - 50px);
  z-index: 1;
  border: 1px solid white;
  width: 100px;
  height: 30px;
  font-size: 20px;
  line-height: 30px;
  border-radius: 4px;
  background-color: rgba(0,0,0,0.5);
}
.upnext-title-style{
  color: #fff;
  text-align: center;
  padding-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;  
}
.upnext-style{
  opacity: 0.7;
}

.playlistUpnextRoot::-webkit-scrollbar {
  width: 8px;
}

.playlistUpnextRoot::-webkit-scrollbar-thumb {
  background-color: #494949;

}

@media (max-width: 978px) {
  .upnext-title-style{
    padding: 0 5px 10px 5px;
  }
  .testingVideo{
    width: 100%;
    height: 700px;
  }
  .for-float-position .jw-flag-floating .jw-wrapper{
    width: 200px!important;
    height: auto;
    bottom: 14px;
    top: initial;
    left: 14px;
    right: initial;
  }
  #countdown-wrapper {
    top: 10%;
  }
  #countdown {
    height: 50px;
    width: 50px;
  }
  #countdown svg {
    width: 50px;
    height: 50px;
  }
  #countdown svg circle {
    stroke-dasharray: 170px;
    stroke-width: 3px;
  }
  @keyframes countdown {
    from {
    stroke-dashoffset: 0px;
    }
    to {
    stroke-dashoffset: 185px;
    }
  }
  #countdown-number {
      width: 50px;
      height: 50px;
  }
  #countdown .arrow-right {
      position: absolute;
      z-index: -1; 
      top: 15px;
      left: 20px;
      bottom: 0;
      width: 0;
      height: 0;
      display: inline-block;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 15px solid white;
  }
  #countdown-cancel {
    top: calc((50%) + 65px);
  }
  #countdown-number {
    color: #fff;
    line-height: 50px;
    width: 50px;
    height: 50px;
  }
}
#test-clappr-player .player-poster[data-poster] .play-wrapper[data-poster] svg {
  height: 60%;
  padding-top: 10px;
}
@media only screen and (max-width: 600px) {
  #test-clappr-player .player-poster[data-poster] .play-wrapper[data-poster] svg{
    height: 45px;
    padding-top: 10px;
  }
}
.sale-video #unmute-button{
  display: none!important;
}

#sales-clappr-player .player-poster{
  background-size: cover;
}

#sales-clappr-player .player-poster[data-poster] .play-wrapper[data-poster] svg {
  height: 100%;
  width: 40px;
}
`
function GlobalStyle (){
    return(
        <GlobalStyleComponent />
    )
}
export default GlobalStyle;