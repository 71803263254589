export const extractYoutubeVideoID = (url) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    const match = url.match(regExp);
    if ( match && match[7].length == 11 ){
        return match[7];
    }
    return false;   
}
export const duwunCoverLogo = `https://duwun-data.sgp1.cdn.digitaloceanspaces.com/static/categories/Duwun_cover_1200x628.png`

export const getDesktopNewsItemVideoThumbnail = (youtubeID) => `https://img.youtube.com/vi/${youtubeID}/sddefault.jpg`

export const getMobileNewsItemVideoThumbnail = (youtubeID) => `https://img.youtube.com/vi/${youtubeID}/default.jpg`

export const getFeaturedVideoThumbnail = (youtubeID) => `https://img.youtube.com/vi/${youtubeID}/maxresdefault.jpg`

export const dateSplit = (date) => {
    const dateItem = date.split(' ')[0];
    return dateItem
}

export const isFacebookApp = () => {
    const ua = navigator.userAgent || navigator.vendor || window.opera;
    return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
}

export const findCategory = (allCategories, slug) => {
    let currentCategoryObj = null;
    let currentSubCategoryObj = null;
    if( allCategories && allCategories.length > 0){
        outerloop: for(let i= 0; i < allCategories.length; i++){
            if(allCategories[i].slug == slug ){ //&& allCategories[i].sub_categories.length == 0
              currentCategoryObj = allCategories[i];
              break outerloop;
            }
            var currentChildren = allCategories[i].sub_categories;
            if(currentChildren && currentChildren.length > 0){
              for(let j= 0; j < currentChildren.length; j++){
                if(currentChildren[j].slug === slug){
                  currentSubCategoryObj=currentChildren[j];
                  currentCategoryObj = allCategories[i].sub_categories[j].slug == slug ? allCategories[i]: null ;
                  break outerloop;
                }
              }
            }
        }
    }
    return ({currentCategoryObj, currentSubCategoryObj});
  }
export const covidSections = {
    covid19 : "covid-19",
    covid19Local : "covid-19-local-news",
    covid19Global : "covid-19-global-news",
    covid19Stats : "covid-19-stats",
    covid19Map : "covid-19-map",
    covid19Charts : "charts",
    covid19MOHS : "mohs",
    covid19JHU : "jhu",
    newCovid19Stats: "covid-19-statistics",
    newCovid19Map: "covid-19-map",
    newCovid19Charts : "covid-19-charts",
    newCovid19MOHS : "covid-19-mohs",
    newCovid19JHU : "covid-19-jhu",
  }

export const getCategoryColors = (category) => {
    let primary;
    let secondary;
    let tertiary;
    switch (category)  {
      case "news":
        primary = '#F9461E';
        secondary = '#F9AE3C';
        tertiary = '#ffffff';
      break;
      case "lifestyle":
        primary = '#F3D60B';
        secondary = '#FB9817';
        tertiary = '#50C7FF';
      break;
      case "entertainment":
        primary = '#FF324B';
        secondary = '#D673FF';
        tertiary = '#00EF9F';
      break;
      case "movie-music":
        primary = '#FF324B';
        secondary = '#D673FF';
        tertiary = '#00EF9F';
      break;
      case "cele-yatkwat":
        primary = '#FF324B';
        secondary = '#D673FF';
        tertiary = '#00EF9F';
      break;
      case "celebrities":
        primary = '#FF324B';
        secondary = '#D673FF';
        tertiary = '#00EF9F';
      break;
      case "sport-news":
        primary = '#2D3CC7';
        secondary = '#19D6C7';
        tertiary = '#CCEA0A';
      break;
      case "sport":
        primary = '#2D3CC7';
        secondary = '#19D6C7';
        tertiary = '#CCEA0A';
      break;
      case "women":
        primary = '#6720F0';
        secondary = '#FFBBD4';
      tertiary = '#F8EE53';
      break;
      case "tech":
        primary = '#0AD15A';
        secondary = '#B3FF00';
        tertiary = '#8164F4';
      break;
      case "careers":
        primary = '#50C7FF';
        secondary = '#0A40E9';
        tertiary = '#DBDBDB';
      break;
      default:   
        primary = '#F9461E';
        secondary = '#F9AE3C';
        tertiary = '#ffffff';
        break;
    }
    return(
      { primary, secondary, tertiary}
    )
  }
  export const capitalizeLetter = (str) => {
    const lowerCase = str.toLowerCase();
    return str.charAt(0).toUpperCase() + lowerCase.slice(1);
  }