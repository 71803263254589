import { SheetsRegistry } from 'jss';
import { createMuiTheme, createGenerateClassName } from '@material-ui/core/styles';
// import update from 'immutability-helper';

const commonThemeClasses = {
    overrides: {
        MuiCssBaseline: {
          '@global': {
         
              
          },
        },
    },
    palette: {
        primary: {
            main: '#F9461E',
            contrastText: '#fff',
        },
        secondary: {
            main: '#0A0A0A',
        },
        whiteColor:{
            contrastText: "#fff",
        } ,
        // type: theme.paletteType,
        // background: {
        //     default: theme.paletteType === 'light' ? '#000' : '#fff',
        // },
    },
    spacing: {
        unit: 10
    },
}
const theme = createMuiTheme({
    ...commonThemeClasses,
    typography:{
        fontSize: 16,
        fontFamily: [
            "SpaceGrotesk", "Padauk", "DuwunFont", "serif"
        ].join(','),
        useNextVariants: true,
    }
})
const themeZawgyi = createMuiTheme({
    ...commonThemeClasses,
    typography:{
        fontFamily: [
            "Zawgyi-One", "ZawgyiOne", 'serif'
        ].join(','),
        useNextVariants: true,
    }
})

function createPageContext() {
    return {
        theme,
        themeZawgyi,
        // This is needed in order to deduplicate the injection of CSS in the page.
        sheetsManager: new Map(),
        // This is needed in order to inject the critical CSS.
        sheetsRegistry: new SheetsRegistry(),
        // The standard class name generator.
        generateClassName: createGenerateClassName(),
    };
}

export default function getPageContext() {
    // Make sure to create a new context for every server-side request so that data
    // isn't shared between connections (which would be bad).
    if (!process.browser) {
        return createPageContext();
    }

    // Reuse context on the client-side.
    if (!global.__INIT_MATERIAL_UI__) {
        global.__INIT_MATERIAL_UI__ = createPageContext();
    }

    return global.__INIT_MATERIAL_UI__;
}

// const themeZawgyi = update(theme, {
//     typography:{
//         fontFamily: "Zawgyi-One,ZawgyiOne,serif",
//         useNextVariants: true,
//     }
// }); // (Doesn't work: Invariant Violation)
// const themeZawgyi = {...theme}; // Shallow copy using Spread Syntax (Doesn't work for child properties)
// const themeZawgyi = JSON.parse(JSON.stringify(theme)); // Deep copy using JSON's parse and stringify (Doesn't copy functions)
// const themeZawgyi = ((...theme) => {
//     let target = {}
//     theme.forEach((th) => {
//       return Object.keys(th).forEach((key) => {
//         target[key] = th[key]
//       })
//     })
//     return target
//    })();
// const zgFamily = ["Zawgyi-One", "ZawgyiOne", 'serif'].join(',');
// themeZawgyi.typography.fontFamily = zgFamily;
// themeZawgyi.typography.display4.fontFamily = zgFamily;
// themeZawgyi.typography.display3.fontFamily = zgFamily;
// themeZawgyi.typography.display2.fontFamily = zgFamily;
// themeZawgyi.typography.display1.fontFamily = zgFamily;
// themeZawgyi.typography.headline.fontFamily = zgFamily;
// themeZawgyi.typography.title.fontFamily = zgFamily;
// themeZawgyi.typography.subheading.fontFamily = zgFamily;
// themeZawgyi.typography.body2.fontFamily = zgFamily;
// themeZawgyi.typography.body1.fontFamily = zgFamily;
// themeZawgyi.typography.caption.fontFamily = zgFamily;
// themeZawgyi.typography.button.fontFamily = zgFamily;
// themeZawgyi.typography.h1.fontFamily = zgFamily;
// themeZawgyi.typography.h2.fontFamily = zgFamily;
// themeZawgyi.typography.h3.fontFamily = zgFamily;
// themeZawgyi.typography.h4.fontFamily = zgFamily;
// themeZawgyi.typography.h5.fontFamily = zgFamily;
// themeZawgyi.typography.h6.fontFamily = zgFamily;
// themeZawgyi.typography.subtitle1.fontFamily = zgFamily;
// themeZawgyi.typography.subtitle2.fontFamily = zgFamily;
// themeZawgyi.typography.body1Next.fontFamily = zgFamily;
// themeZawgyi.typography.body2Next.fontFamily = zgFamily;
// themeZawgyi.typography.buttonNext.fontFamily = zgFamily;
// themeZawgyi.typography.captionNext.fontFamily = zgFamily;
// themeZawgyi.typography.overline.fontFamily = zgFamily;

// console.log("themeUni:");
// console.log(theme);
// console.log("themeZawgyi:");
// console.log(themeZawgyi);
