import React, { Component } from "react";
// import * as Sentry from '@sentry/browser';

export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        });      
    //   Sentry.withScope(scope => {
    //     Object.keys(errorInfo).forEach(key => {
    //       scope.setExtra(key, errorInfo[key]);
    //     });
    //     Sentry.captureException(error);
    //   });
    }

    getGAErrorTracker() {
        const getGAErrorTracker = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'UA-67701357-1');
        ga('send', 'pageview', window.location.href+'#errorBoundary');
        `;
    
        return (
        <>
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-67701357-1"></script>
        <script dangerouslySetInnerHTML={{ __html: getGAErrorTracker}} />
        </>
        );
    }   
    
    loadGTM() {
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});let f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?`&l=${l}`:'';j.async=true;j.src=
        `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-5LSZTWB');  
        console.log('GTM-5LSZTWB Loaded');
    }

    trackError() {
        this.loadGTM();
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'errorBoundaryEvent',
            'errorPage': window.location.href+'#errorBoundary',
            'errorStatus': 'Client-Side-JS-Error',
            'errorMessage': this.state.error && this.state.error.toString()
        });
        document.body.style.backgroundColor = "#eee";
        document.body.style.color = "#777";
        document.body.style.fontSize = "0.75em";

        if(!navigator.onLine) {
            if(document.getElementById("error-boundary-title"))            
                document.getElementById("error-boundary-title").textContent = 'You are offline.';
            if(document.getElementById("error-boundary-message"))
                document.getElementById("error-boundary-message").innerHTML = 'Tips:<ul><li>check the internet connection.</li><li>refresh the page again.</li></ul>';
        }
        else {
            if(document.getElementById("error-boundary-title"))
                document.getElementById("error-boundary-title").textContent = 'Something went wrong.';
            if(document.getElementById("error-boundary-message"))
                document.getElementById("error-boundary-message").innerHTML = `
                <p>This page is broken due to an unexpected error. Please check back again soon or try one of the links given below.</p>
                Visit:
                <ul>
                    <li><a href="/" style="color:#777">Home</a></li>
                    <li><a href="/news" style="color:#777">News</a></li>
                    <li><a href="/sport" style="color:#777">Sports</a></li>
                    <li><a href="/entertainment" style="color:#777">Entertainment</a></li>
                    <li><a href="/tech" style="color:#777">Tech</a></li>
                    <li><a href="/women" style="color:#777">Women</a></li>
                    <li><a href="/lifestyle" style="color:#777">Lifestyle</a></li>
                    <li><a href="/videos" style="color:#777">Videos</a></li>
                    <li><a href="/tv" style="color:#777">TV</a></li>
                </ul>`;            
        }
        console.log('#### ErrorBoundary:trackError ####');
        console.log(this.state.error);
    }

    render() {
        if (this.state.error) {
            //render fallback UI
            return (
                <div style={{ marginLeft: "5%" }}>
                    <br /><br />
                    <img style={{MozUserSelect : "none", WebkitUserSelect : "none", msUserSelect : "none", 
                    margin: "auto", width: "70px", height: "77px", objectFit: "cover", objectPosition: "left"}} 
                    src="https://duwun-data.sgp1.cdn.digitaloceanspaces.com/static/image/DuwunLogoWhite.svg" />                    
                    <h1 id="error-boundary-title">Oops! Something went wrong.</h1>
                    <div id="error-boundary-message"></div>
                    <details style={{ whiteSpace: "pre-wrap" }}>
                        {this.state.error && this.state.error.toString()}
                        <br />
                        <small>{this.state.errorInfo.componentStack}</small>
                    </details>             
                    {/* {this.getGAErrorTracker() } */}
                    {/* <a onClick={() => Sentry.showReportDialog()}>Report feedback</a> */}
                </div>
            );
        } else {
            //when there's not an error, render children untouched
            return this.props.children;
        }
    }

    componentDidMount() {
        if(this.state.error) {
            console.log('#### ErrorBoundary:componentDidMount ####');
            this.trackError();
        }
    }

    componentDidUpdate(previousProps, previousState){
        if(this.state.error) {
            console.log('#### ErrorBoundary:componentDidUpdate ####');
            this.trackError();
        }
    }

}
